import {useCallback, useEffect, useState} from 'react';

export default function useResizeObserver(target, callback = undefined) {
  if (!target) {
    throw new Error('No target element provided for useResizeObserver');
  }

  const [entries, setEntries] = useState({});

  const onResize = useCallback(([entry]) => {
    const entryObserver = {
      borderBoxSize: entry.borderBoxSize
        ? entry.borderBoxSize[0]
        : {
            blockSize: entry.contentRect.bottom,
            inlineSize: entry.contentRect.x + entry.contentRect.right,
          },
      contentBoxSize: entry.contentBoxSize
        ? entry.contentBoxSize[0]
        : {
            blockSize: entry.contentRect.height,
            inlineSize: entry.contentRect.width,
          },
    };

    setEntries(entryObserver);
    if (callback) {
      callback(entryObserver);
    }
  });

  useEffect(() => {
    const observer = new ResizeObserver(onResize);

    if (target.current) {
      observer.observe(target.current);
    }

    return () => {
      if (target.current) {
        observer.unobserve(target.current);
      } else {
        observer.disconnect();
      }
    };
  }, [target]);

  return entries;
}

import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import {useRefinementList} from 'react-instantsearch';
import {useEffect} from 'react';
import DropdownItem from './DropdownItem';
import useTopFiltersContext from '../../../context/FiltersContext';
import removeInvisibleTags from '../../../lib/algolia/invisible-tags';

const Dropdown = dynamic(() => import('./Dropdown'));

function MenuSelectwithAlgolia(props) {
  const {updateFilter} = useTopFiltersContext();
  const {items, refine} = useRefinementList(props);
  const {dropdownTitle, attribute, triggerCallback} = props;

  useEffect(() => {
    updateFilter(attribute, !!items?.length);
  }, [items?.length]);

  if (dropdownTitle === 'Template Type') {
    templateTypeTransform(items);
  }

  return items?.length ? (
    <Dropdown {...props}>
      {items.map(item => (
        <DropdownItem
          key={item.value}
          value={item.value}
          label={item.label}
          isChecked={item.isRefined}
          count={item.count}
          handleCheck={refine}
          triggerCallback={triggerCallback ? () => triggerCallback(item) : undefined}
          // eslint-disable-next-line react/destructuring-assignment
          size={props.size}
        />
      ))}
    </Dropdown>
  ) : null;
}

function templateTypeTransform(items) {
  items.forEach(item => {
    if (item.label === 'image') item.label = 'Image';
    else if (item.label === 'blender') item.label = 'Transparent';
    else if (item.label === 'video') item.label = 'Video';
    else if (item.label === 'multi-stage') item.label = 'Multi Device';
    else if (item.label === 'ios-stillshot') item.label = 'App Store Screenshot';
    else if (item.label === 'design_template') item.label = 'Design Templates';
    else if (item.label === 'multi_color') item.label = 'Multi Color';
    else if (item.label === 'interactive_image') item.label = 'Draggable Elements';
    else if (item.label === 'list') item.label = 'Lists';
    else if (item.label === 'workflow') item.label = 'Workflow';
  });
}

MenuSelectwithAlgolia.defaultProps = {
  triggerCallback: () => {},
  dropdownTitle: '',
  size: 'md',
  className: '',
  transformItems: removeInvisibleTags,
};

MenuSelectwithAlgolia.propTypes = {
  /** The name of the attribute in the records. */
  attribute: PropTypes.string.isRequired,
  /** The title displayed in the menu select */
  dropdownTitle: PropTypes.string,
  /** Size of the menu select */
  size: PropTypes.oneOf(['md', 'lg']),
  /** Optional CSS Styles */
  className: PropTypes.string,
  /** Optional callback when clicking on a dropdown item (item: RefinementListItem ) */
  triggerCallback: PropTypes.func,
  /* Receives the items and is called before displaying them. Should return a new array with the same shape as the original array. Useful for transforming, removing, or reordering items. */
  transformItems: PropTypes.func,
};

export default MenuSelectwithAlgolia;

export const PlasmicProps = {
  name: 'MenuSelect',
  props: {
    attribute: {
      type: 'choice',
      options: [
        {value: 'device_tags', label: 'Device'},
        {value: 'stage_tags', label: 'Stage'},
        {value: 'gender_tags', label: 'Gender'},
        {value: 'age_tags', label: 'Age'},
        {value: 'ethnicity_tags', label: 'Ethnicity'},
        {value: 'model_tags', label: 'Model'},
        {value: 'bundle_tags', label: 'Bundle'},
        {value: 'template_type', label: 'Template Type'},
      ],
      defaultValue: 'device_tags',
      displayName: 'Tag',
    },
    dropdownTitle: {
      type: 'string',
      defaultValue: MenuSelectwithAlgolia.defaultProps.dropdownTitle,
      displayName: 'Title',
    },
    size: {
      type: 'choice',
      options: ['md', 'lg'],
      defaultValue: MenuSelectwithAlgolia.defaultProps.size,
    },
    operator: {
      type: 'choice',
      options: ['or', 'and'],
      defaultValue: 'and',
    },
  },
  importPath: './components/Discovery/MenuSelect/MenuSelectwithAlgolia',
  isDefaultExport: true,
};

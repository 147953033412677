import PropTypes from 'prop-types';
import {forwardRef} from 'react';
import {CheckboxItem} from '@radix-ui/react-dropdown-menu';
import ListItem from '../Common/ListItem/ListItem';

const DropdownItem = forwardRef(
  ({value, handleCheck, isChecked, label, count, size, ...props}, forwardedRef) => {
    const {triggerCallback} = props;

    const handleTriggerClick = item => {
      if (!triggerCallback) return;
      triggerCallback(item);
    };

    return (
      <CheckboxItem
        key={value}
        ref={forwardedRef}
        checked={isChecked}
        onCheckedChange={() => handleCheck(value)}
        onClick={handleTriggerClick}
        className="flex w-full items-center justify-start pl-2 pr-4 outline-none hover:cursor-pointer [&[data-highlighted]]:bg-gray-100"
      >
        <ListItem value={value} label={label} isChecked={isChecked} count={count} size={size} />
      </CheckboxItem>
    );
  }
);

DropdownItem.displayName = 'DropdownItem';

DropdownItem.defaultProps = {
  count: null,
  triggerCallback: () => false,
  handleCheck: () => {},
  isChecked: false,
  size: 'md',
};

DropdownItem.propTypes = {
  value: PropTypes.string.isRequired,
  handleCheck: PropTypes.func,
  isChecked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  count: PropTypes.number,
  size: PropTypes.string,
  /** Optional callback when clicking on a dropdown item (item: RefinementListItem ) */
  triggerCallback: PropTypes.func,
};

export default DropdownItem;

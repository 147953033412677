import {useCallback, useMemo} from 'react';
import {useCurrentRefinements, useHits} from 'react-instantsearch';
import CurrentRefinements from './CurrentRefinements';
import ClearRefinements from './ClearRefinements';
import Headerstrip from '../UI/Molecules/Headerstrip';
import removeInvisibleTags from '../../lib/algolia/invisible-tags';

export default function CurrentRefinementsWithAlgolia(props) {
  const {items, refine} = useCurrentRefinements({props, transformItems});
  const {hits} = useHits(props);

  const cachedRefine = useCallback(
    item => {
      refine(item);
    },
    [refine]
  );

  const refinements = useMemo(() => {
    return items
      .map(item => {
        return item.refinements;
      })
      .flat();
  }, [items]);

  if (refinements.length <= 0) {
    return null;
  }

  const MODEL_FACET_TAG = 'model_tags';

  const JUST_ONE_FACET_SELECTED = 1;

  const hideOnModelFacet = refinement => {
    return (
      refinement.length === JUST_ONE_FACET_SELECTED && refinement[0]?.attribute === MODEL_FACET_TAG
    );
  };

  const showZeroMatchesWarning = () => {
    if (hits.length === 0) {
      return (
        <Headerstrip
          message={
            'Your filter selections didn’t return any matches.\r\nPlease remove one or more filters to show some results.'
          }
          type="warning"
        />
      );
    }
  };

  return (
    <div className="flex w-full flex-wrap items-center gap-2 py-2">
      <ClearRefinements
        className={`${hideOnModelFacet(refinements) === false && 'lg:block'} hidden`}
        label="Clear All"
      />
      <CurrentRefinements refinements={refinements} refine={cachedRefine} />
      {showZeroMatchesWarning()}
    </div>
  );
}

function transformItems(items, metadata) {
  return items.map(item => ({
    ...item,
    refinements: removeInvisibleTags(item.refinements, metadata),
  }));
}

export const PlasmicProps = {
  name: 'CurrentRefinements',
  props: {},
  importPath: './components/Discovery/CurrentRefinementsWithAlgolia',
  isDefaultExport: true,
};

import React from 'react';
import {usePagination} from 'react-instantsearch';
import PropTypes from 'prop-types';
import Pagination from './Pagination';

export default function PaginationWithAlgolia({onHandleSelector, ...props}) {
  const {canRefine, isFirstPage, nbPages, isLastPage, currentRefinement, pages, refine} =
    usePagination(props);

  if (!canRefine) {
    return null;
  }

  return (
    <Pagination
      isFirstPage={isFirstPage}
      totalPages={nbPages}
      isLastPage={isLastPage}
      currentPage={currentRefinement}
      pages={pages}
      handleSelection={args => {
        onHandleSelector();
        return refine(args);
      }}
    />
  );
}

PaginationWithAlgolia.propTypes = {
  onHandleSelector: PropTypes.func,
};

PaginationWithAlgolia.defaultProps = {
  onHandleSelector: () => {},
};

import {useEffect, useState, useRef, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import BaseButton from '../UI/Molecules/BaseButton';
import Text from '../UI/Atoms/Typography/Text';
import {IcSettings03, IcCloseSm} from '../UI/Atoms/Icons';
import useResizeObserver from '../../hooks/useResizeObserver';
import {FiltersProvider} from '../../context/FiltersContext';
import TopFilters from './TopFilters';
import useNavbarHeight from '../../hooks/useNavbarHeight';
import useEffectFreezeBody from '../../hooks/useEffectFreezeBody';
import useCampaignContext from '../../context/CampaignContext';

const SCROLL_STYLES = {
  enabled: {
    side: 'lg:fixed lg:h-screen lg:overflow-y-auto lg:top-[calc(var(--navbar-height)+var(--headerstrip-height))] top-[calc(var(--navbar-height)+var(--headerstrip-height))] lg:max-w-[236px]',
    top: 'fixed lg:top-[calc(var(--navbar-height)+var(--headerstrip-height))] z-[1] top-[calc(var(--navbar-height)+var(--headerstrip-height))] lg:w-[calc(100%-250px)]',
    content: 'min-h-screen mt-[calc(var(--filter-top))]',
  },
  disabled: {
    side: 'lg:h-[calc(100%-4px)] lg:max-h-[calc(var(--content-height)+var(--filter-top))] lg:overflow-hidden',
    top: 'lg:w-full',
    content: '',
  },
};

const REFINEMENT_LIST_STYLES = {
  hidden: 'lg:hidden',
  visible: 'lg:overflow-y-visible',
  scroll: 'lg:overflow-y-auto',
};

function Backdrop() {
  return (
    <div className="backdrop fixed inset-0 z-10 block h-screen w-screen bg-black opacity-70 lg:hidden">
      &nbsp;
    </div>
  );
}

export default function FiltersMenu({select, topFilters, refinementList, children, showHeader}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filtersFixed, setFiltersFixed] = useState(false);
  const [scrollStyles, setScrollStyles] = useState(SCROLL_STYLES.disabled);
  const [refinementListStyles, setRefinementListStyles] = useState(REFINEMENT_LIST_STYLES.visible);
  const topFilterContainer = useRef(null);
  const fullContainer = useRef(null);
  const contentContainer = useRef(null);
  const refinementListContainer = useRef(null);
  const mainContainer = useRef(null);
  const navHeight = useNavbarHeight();
  const {headerstripHeight} = useCampaignContext();

  const handleOpenModal = useCallback(isModalOpenState => {
    setIsModalOpen(isModalOpenState);
  }, []);

  useEffectFreezeBody(isModalOpen);

  const updateRefinementListSize = useMemo(() => {
    const throttledUpdate = () => {
      const container = mainContainer.current;
      if (!container) return;

      const clientRect = container.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (clientRect.top <= navHeight && windowHeight <= clientRect.bottom) {
        setFiltersAndRefinementListStyles(true, REFINEMENT_LIST_STYLES.scroll);
        refinementListContainer.current.style.setProperty('height', `${windowHeight - 159}px`);
      } else {
        setFiltersAndRefinementListStyles(
          false,
          determineRefinementListStyle(clientRect, windowHeight)
        );
      }
    };

    const setFiltersAndRefinementListStyles = (refinementFiltersFixed, refinementListStyle) => {
      setFiltersFixed(refinementFiltersFixed);
      setRefinementListStyles(refinementListStyle);
    };

    const determineRefinementListStyle = (clientRect, windowHeight) => {
      if (windowHeight > clientRect.height) {
        return REFINEMENT_LIST_STYLES.scroll;
      }
      if (windowHeight > clientRect.bottom) {
        return REFINEMENT_LIST_STYLES.hidden;
      }
      return REFINEMENT_LIST_STYLES.visible;
    };

    return throttledUpdate;
  }, [navHeight, setFiltersFixed, setRefinementListStyles]);

  const closeModalOnClick = useCallback(
    event => {
      if (event.target.classList.contains('backdrop')) {
        handleOpenModal(false);
      }
    },
    [handleOpenModal]
  );

  const closeModalOnEscape = useCallback(
    event => {
      if (event.key === 'Escape') {
        handleOpenModal(false);
      }
    },
    [handleOpenModal]
  );

  useResizeObserver(topFilterContainer, entry => {
    if (fullContainer.current) {
      fullContainer.current.style.setProperty('--filter-top', `${entry.borderBoxSize.blockSize}px`);
    }
  });

  useResizeObserver(contentContainer, entry => {
    if (fullContainer.current) {
      fullContainer.current.style.setProperty(
        '--content-height',
        `${entry.borderBoxSize.blockSize}px`
      );
    }

    updateRefinementListSize();
  });

  useEffect(() => {
    window.addEventListener('click', closeModalOnClick);
    window.addEventListener('keyup', closeModalOnEscape);
    window.addEventListener('scroll', updateRefinementListSize);

    return () => {
      window.removeEventListener('click', closeModalOnClick);
      window.removeEventListener('keyup', closeModalOnEscape);
      window.removeEventListener('scroll', updateRefinementListSize);
    };
  }, [navHeight]);

  useEffect(() => {
    setScrollStyles(filtersFixed ? SCROLL_STYLES.enabled : SCROLL_STYLES.disabled);
  }, [filtersFixed]);

  return (
    <div
      className={`${
        showHeader ? 'block lg:flex' : 'hidden'
      } relative w-full max-w-full bg-gray-ce-page lg:h-auto lg:flex-auto lg:justify-between`}
      style={{
        '--filter-top': '0px',
        '--content-height': '0px',
        '--navbar-height': `${navHeight}px`,
        '--headerstrip-height': `${headerstripHeight}px`,
      }}
      ref={fullContainer}
    >
      {isModalOpen && <Backdrop />}
      <div className="relative flex min-w-[250px] items-center justify-between pl-3.5 lg:block">
        <div
          className={`fixed left-0 right-0 top-[20vh] z-10 flex h-[80vh] w-full flex-col content-center justify-center rounded-t-lg bg-white lg:relative lg:top-0 lg:z-0 lg:block lg:h-full lg:bg-transparent ${
            !isModalOpen && 'hidden'
          }`}
          data-testid="refinement-list"
        >
          <div className={`flex h-[calc(100%-56px)] flex-col lg:w-full ${scrollStyles.side}`}>
            <div className="flex w-full content-center justify-between border-b border-cold-gray-500 px-4 py-4 lg:sticky lg:top-0 lg:border-0 lg:bg-gray-ce-page lg:px-0 lg:pb-2 lg:pt-5">
              <div className="flex items-center">
                <IcSettings03 variant="multi" className="mr-2 h-6 w-6" />
                <Text size="2xl" as="span" weight="semibold">
                  Filters
                </Text>
              </div>
              <button type="button" className="lg:hidden" onClick={() => handleOpenModal(false)}>
                <IcCloseSm className="h-8 w-8" />
              </button>
            </div>
            <div
              className={`h-[calc(100%-40px)] w-full overflow-y-auto px-4 lg:top-0 lg:h-full lg:max-w-[236px] lg:px-0 ${refinementListStyles}`}
              ref={refinementListContainer}
            >
              {refinementList}
            </div>
          </div>
          <div className="z-10 px-5 py-2 shadow-[0px_-2px_4px_rgba(0,0,0,0.25)] lg:hidden">
            <BaseButton className="w-full !bg-secondary-500" onClick={() => handleOpenModal(false)}>
              Apply Filters
            </BaseButton>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col" ref={mainContainer}>
        <div
          className={`w-full bg-gray-ce-page px-3 pt-2 lg:flex lg:flex-wrap lg:items-start lg:justify-between lg:gap-y-4 lg:pt-4 ${scrollStyles.top}`}
          ref={topFilterContainer}
        >
          <div className="mb-4 flex items-center justify-start gap-x-5 lg:hidden">
            {refinementList && (
              <BaseButton
                leftIcon={<IcSettings03 className="h-5 w-5" />}
                onClick={() => handleOpenModal(true)}
                variant="secondaryOutlined"
                aria-label="Filters"
              >
                Filters
              </BaseButton>
            )}
          </div>
          <FiltersProvider>
            <TopFilters filtersContainer={topFilters} sortBy={select} />
          </FiltersProvider>
        </div>
        <div
          className={`grid w-full justify-items-stretch ${scrollStyles.content}`}
          ref={contentContainer}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

FiltersMenu.propTypes = {
  children: PropTypes.node,
  select: PropTypes.node,
  topFilters: PropTypes.node,
  refinementList: PropTypes.node,
  showHeader: PropTypes.bool,
};

FiltersMenu.defaultProps = {
  children: null,
  select: null,
  topFilters: null,
  refinementList: null,
  showHeader: true,
};

export const PlasmicData = {
  name: 'FiltersMenu',
  props: {
    children: {
      type: 'slot',
      defaultValue: [
        {
          type: 'component',
          name: 'GridWithTemplateResults',
          props: {
            paginationType: 'normal',
          },
        },
      ],
    },
    select: {
      type: 'slot',
      defaultValue: [
        {
          type: 'component',
          name: 'SortBy',
        },
      ],
    },
    topFilters: {
      type: 'slot',
      defaultValue: {
        type: 'hbox',
        children: [
          {
            type: 'component',
            name: 'MenuSelect',
            props: {
              attribute: 'age_tags',
              dropdownTitle: 'Age',
            },
          },
          {
            type: 'component',
            name: 'MenuSelect',
            props: {
              attribute: 'gender_tags',
              dropdownTitle: 'Gender',
            },
          },
          {
            type: 'component',
            name: 'MenuSelect',
            props: {
              attribute: 'ethnicity_tags',
              dropdownTitle: 'Ethnicity',
            },
          },
        ],
      },
    },
    refinementList: {
      type: 'slot',
      defaultValue: [
        {
          type: 'component',
          name: 'RefinementList',
          props: {
            title: 'Templates',
            attribute: 'device_tags',
            limit: 10,
            showMoreLimit: 60,
            showMore: true,
            operator: 'and',
          },
        },
        {
          type: 'component',
          name: 'RefinementList',
          props: {
            title: 'Tags',
            attribute: 'stage_tags',
            limit: 100,
            operator: 'and',
          },
        },
      ],
    },
    className: {
      type: 'string',
      defaultValue: '',
    },
  },
  importPath: './components/Discovery/FiltersMenu',
  isDefaultExport: true,
};

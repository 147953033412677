import dynamic from 'next/dynamic';
import {createContext, useMemo, useState, forwardRef} from 'react';
import PropTypes from 'prop-types';
import {Root, Trigger, Content, Viewport, Value} from '@radix-ui/react-select';
import {Root as LabelRoot} from '@radix-ui/react-label';
import {IcChevronExpand, IcCloseSm} from '../Atoms/Icons';
import useWindowDimensions from '../../../hooks/wizard/useWindowDimensions';
import {breakpoints} from '../../../lib/constants';

const Backdrop = dynamic(() => import('../../LogoMaker/Steps/Common/Backdrop'));
const Text = dynamic(() => import('../Atoms/Typography/Text'));

export const VARIANT = {
  standard: {
    sizes: {
      sm: {
        text: 'md',
        chevronIcon: 'h-5 w-5',
        checkIcon: 'h-4 w-4',
        trigger: 'py-[9px] px-3 h-9',
        option: 'py-[5px] pl-2.5',
      },
      md: {
        text: 'lg',
        chevronIcon: 'h-5 w-5',
        checkIcon: 'h-5 w-5',
        trigger: 'py-2 px-3 h-9',
        option: 'py-1.5 pl-3',
      },
      lg: {
        text: 'xl',
        chevronIcon: 'h-6 w-6',
        checkIcon: 'h-5 w-5',
        trigger: 'py-2.5 px-2 h-11',
        option: 'py-2 pl-3',
      },
    },
    theme: {
      'cold-gray': {
        label: 'text-cold-gray-800',
        trigger:
          'border-2 border-cold-gray-400 text-cold-gray-800 hover:border-cold-gray-600 focus:outline-cold-gray-800 ' +
          '[&[data-disabled]]:bg-color-cold-gray-100 [&[data-disabled]]:border-cold-gray-400 [&[data-disabled]]:text-cold-gray-400',
        triggerChevronIcon: '',
        option:
          'text-cold-gray-700 [&[data-highlighted]]:bg-cold-gray-300 [&[data-disabled]]:bg-cold-gray-100 [&[data-disabled]]:text-cold-gray-400',
      },
      gray: {
        label: 'text-gray-900',
        trigger:
          'border border-gray-300 text-gray-800 hover:border-cold-gray-600 focus:outline-cold-gray-800 ' +
          '[&[data-disabled]]:bg-cold-gray-100 [&[data-disabled]]:border-gray-300 [&[data-disabled]]:text-gray-300 data-[state=open]:bg-gray-100 data-[state=open]:border-gray-300',
        triggerChevronIcon: 'text-gray-900',
        option:
          'text-gray-800 [&[data-highlighted]]:bg-gray-100 [&[data-disabled]]:bg-white [&[data-disabled]]:text-gray-300',
      },
    },
    trigger:
      'bg-white flex rounded-md [&[data-error]]:border-danger-500 [&[data-state="open"]>.chevrondown-icon]:rotate-180',
    option: 'mb-1 last:mb-0 flex bg-white',
    optionText: 'm-auto ml-0 cursor-default',
    content:
      'border border-gray-300 rounded-md bg-white py-2 shadow-md z-40 w-[160px] shadow-black',
    contentOffset: 2,
  },
};

export const SelectContext = createContext({
  size: 'md',
  variant: 'standard',
});

const Select = forwardRef(function Select(
  {
    size,
    disabled,
    error,
    variant,
    theme,
    onOpenChange,
    defaultOpen,
    open,
    onChange,
    placeholder,
    children,
    label,
    className,
    popper,
    ...props
  },
  ref
) {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const {width} = useWindowDimensions();
  const itIsNotPopper = width < breakpoints && popper !== true;
  const handleOnOpenChange = openState => {
    setIsOpen(openState);
    onOpenChange(openState);
  };

  const defaultContextData = useMemo(() => ({
    size,
    variant,
    theme,
  }));
  const currentVariant = VARIANT[variant];
  const variantTheme = currentVariant.theme[theme];
  const variantSizes = currentVariant.sizes[size];
  const triggerProps = {};
  if (error) {
    triggerProps['data-error'] = true;
  }

  return (
    <SelectContext.Provider value={defaultContextData}>
      {label ? (
        <LabelRoot htmlFor="select_trigger">
          <Text size={size} className={variantTheme.label} as="span">
            <span data-testid="label">{label}</span>
          </Text>
        </LabelRoot>
      ) : null}
      <Root
        {...props}
        onValueChange={onChange}
        onOpenChange={handleOnOpenChange}
        open={open || isOpen}
      >
        <Trigger
          disabled={disabled}
          className={`flex content-center justify-between ${currentVariant.trigger} ${variantSizes.trigger} ${variantTheme.trigger} ${className}`}
          {...triggerProps}
          ref={ref}
          data-testid="trigger"
          id="select_trigger"
        >
          <Text className="!leading-none" size={variantSizes.text} as="span">
            {placeholder ? <Value>{placeholder}</Value> : <Value />}
          </Text>
          <IcChevronExpand
            className={`chevrondown-icon -mt-0.5 ml-3 ${variantSizes.chevronIcon} ${variantTheme.triggerChevronIcon}`}
          />
        </Trigger>
        {isOpen && itIsNotPopper && <Backdrop />}
        <Content
          position={itIsNotPopper ? 'item-aligned' : 'popper'}
          sideOffset={currentVariant.contentOffset}
          align="end"
          className={`${currentVariant.content} ${
            itIsNotPopper &&
            '!fixed bottom-0 left-0 h-[60vh] w-full rounded-t-lg pt-[18px] text-right'
          }`}
          data-testid="content"
          ref={selectOptionRef =>
            selectOptionRef?.addEventListener('touchend', e => e.preventDefault())
          }
        >
          {itIsNotPopper && (
            <>
              <div
                className={`flex content-center px-4 pb-3 ${
                  placeholder ? 'justify-between' : 'justify-end'
                }`}
              >
                {placeholder && (
                  <Text as="span" size="xl" className="font-semibold	">
                    {placeholder}
                  </Text>
                )}
                <button className="relative h-4 w-4" onClick={() => setIsOpen(false)} type="button">
                  <IcCloseSm className="absolute -left-2 -top-2 h-8 w-8" />
                </button>
              </div>
              <hr className="m-auto mb-3 h-px w-[calc(100%-24px)] border-0 bg-cold-gray-200" />
            </>
          )}
          <Viewport>{children}</Viewport>
        </Content>
      </Root>
    </SelectContext.Provider>
  );
});

Select.defaultProps = {
  size: 'md',
  disabled: false,
  error: false,
  variant: 'standard',
  theme: 'cold-gray',
  onOpenChange: () => {},
  open: false,
  defaultOpen: false,
  onChange: () => {},
  placeholder: null,
  label: null,
  className: '',
  popper: true,
  children: null,
};

Select.propTypes = {
  size: PropTypes.oneOf(['lg', 'md', 'sm']),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  variant: PropTypes.oneOf(['standard']),
  theme: PropTypes.oneOf(['cold-gray', 'gray']),
  onOpenChange: PropTypes.func,
  open: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  popper: PropTypes.bool,
  children: PropTypes.node,
};

export default Select;

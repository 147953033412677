import PropTypes from 'prop-types';
import FilterChip from '../UI/Molecules/FilterChip';

export default function CurrentRefinements({refinements, refine}) {
  const MODEL_FACET_TAGS = ['model_tags'];

  const excludeFacet = refinement => {
    return MODEL_FACET_TAGS.includes(refinement.attribute);
  };

  return refinements?.map(refinement => {
    if (excludeFacet(refinement)) {
      return;
    }
    return (
      <FilterChip
        attribute={refinement.attribute}
        type={refinement.type}
        key={refinement.value}
        label={refinement.label}
        value={refinement.value}
        removeRefinement={refine}
      />
    );
  });
}

CurrentRefinements.propTypes = {
  /** List of refinements */
  refinements: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      type: PropTypes.oneOf([
        'facet',
        'exclude',
        'disjunctive',
        'hierarchical',
        'numeric',
        'query',
        'tag',
      ]).isRequired,
      attribute: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** Removal function from provider. */
  refine: PropTypes.func.isRequired,
};

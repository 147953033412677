import PropTypes from 'prop-types';
import {useSortBy} from 'react-instantsearch';
import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';
import {stagingSortByConfig, shouldUseStagingData} from '../../lib/algolia/client';
import Select from '../UI/Molecules/Select';
import Option from '../UI/Molecules/Option';
import {
  SORTBY_BESTSELLING_CLICKED,
  SORTBY_NEWEST_CLICKED,
  SORTBY_POPULAR_CLICKED,
  trackEvent,
} from '../../lib/analytics';

const eventOption = [
  {label: 'Popular', event: SORTBY_POPULAR_CLICKED},
  {label: 'Best Selling', event: SORTBY_BESTSELLING_CLICKED},
  {label: 'Newest', event: SORTBY_NEWEST_CLICKED},
];

export default function SelectWithAlgoliaSortBy({...props}) {
  const router = useRouter();
  const [config, setConfig] = useState(props);

  useEffect(() => {
    if (shouldUseStagingData(router)) {
      setConfig({
        ...stagingSortByConfig,
      });
    } else {
      setConfig(props);
    }
  }, [router]);

  const {currentRefinement, options, refine} = useSortBy(config);

  const handleSortByChange = index => {
    // we first grab the selected option label
    // Then we find the corresponding event by comparing both labels, 1 from the Obj in line 14,
    // and the other from the SortBy Hook
    const {event, label} = eventOption.find(
      option =>
        options.find(selectedOption => index === selectedOption.value).label === option.label
    );
    trackEvent(event, {label});
    refine(index);
  };

  return (
    <div className="flex items-center">
      <Select value={currentRefinement} onChange={handleSortByChange} theme="gray">
        {options.map(({value, label}) => (
          <Option key={value} value={value} label={label} />
        ))}
      </Select>
    </div>
  );
}
SelectWithAlgoliaSortBy.defaultProps = {
  items: [
    {value: 'Stage_production', label: 'Popular'},
    {value: 'Stage_production_replica_best_selling', label: 'Best Selling'},
    {value: 'Stage_production_replica_newest', label: 'Newest'},
  ],
  defaultRefinement: 'Stage_production',
};
SelectWithAlgoliaSortBy.propTypes = {
  /* A list of different indices to choose from. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  /* Default refinement index used */
  defaultRefinement: PropTypes.string,
};

export const PlasmicProps = {
  name: 'SortBy',
  importPath: './components/Discovery/SelectWithAlgoliaSortBy',
  isDefaultExport: true,
  props: {},
};

import PropTypes from 'prop-types';
import {useClearRefinements} from 'react-instantsearch';
import BaseButton from '../UI/Molecules/BaseButton';

export default function ClearRefinements({label, className}) {
  const {refine, canRefine} = useClearRefinements();

  if (!canRefine) return null;

  return (
    <BaseButton className={`underline ${className}`} onClick={refine} variant="textGray">
      {label}
    </BaseButton>
  );
}

ClearRefinements.defaultProps = {
  label: 'Clear All',
  className: '',
};
ClearRefinements.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
};
export const PlasmicData = {
  name: 'ClearRefinements',
  props: {
    label: {
      type: 'string',
      defaultValue: ClearRefinements.defaultProps.label,
    },
  },
  importPath: './components/Discovery/ClearRefinements',
  isDefaultExport: true,
};

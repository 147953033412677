import dynamic from 'next/dynamic';
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ProductBar from './ProductBar';
import Text from '../Atoms/Typography/Text';
import FavoriteIconButton from '../Molecules/IconButton/FavoriteIconButton';
import Ribbon from '../Molecules/Ribbon';
import {productTypes} from '../../../lib/constants';
import {isBrowser, sanitizeUrl} from '../../../lib/utils/common';
import algoliaInsights from '../../../lib/algolia/insights';

import styles from '../../Discovery/Common/Grid/NewMasonryGrid.module.css';

const BundleElements = dynamic(() => import('./BundleElements'));

export default function TemplateCard({
  link,
  image,
  preview,
  altText,
  bundle,
  type,
  title,
  category,
  free,
  disabled,
  favorite,
  onFavoriteClick,
  openModal,
  id,
  position,
  imagePath,
  width,
  height,
  isNewMasonry,
  ...props
}) {
  const [isHovering, setIsHovered] = useState(false);
  const hoverIn = () => setIsHovered(true);
  const hoverOut = () => setIsHovered(false);
  const [overlayClass, setOverlayClass] = useState('hidden');
  const loadingIn = () => setOverlayClass('animate-fadeIn');
  const loadingOut = () => {
    const loadingTimeout = setTimeout(() => setOverlayClass('hidden'), 300);
    setOverlayClass('animate-fadeOut');
    return loadingTimeout;
  };
  const [videoPreviewUrl, setVideoPreviewUrl] = useState('');
  const updateVideoPreview = () => setVideoPreviewUrl(sanitizeUrl(origin + preview));
  const [loadedImg, setLoadedImg] = useState(false);
  const [loadedVid, setLoadedVid] = useState(false);

  const extension = preview?.substr(preview.lastIndexOf('.') + 1);
  const hasVideoPreview = extension?.includes('mp4') || extension?.includes('mov');

  const ratio = height / width;

  useEffect(() => {
    if (hasVideoPreview && isHovering && !loadedVid && preview !== null) {
      loadingIn();
      updateVideoPreview();
    } else if (!hasVideoPreview && isHovering && !loadedImg) {
      loadingIn();
    }
  }, [isHovering]);

  useEffect(() => {
    const loadingTimeout = loadingOut();
    return () => clearTimeout(loadingTimeout);
  }, [loadedImg, loadedVid]);

  const originEnvironment =
    isBrowser() && window.location.origin.includes('staging')
      ? 'https://staging.placeit.net'
      : 'https://placeit.net';

  const origin = isBrowser() && preview?.includes('https://') ? '' : originEnvironment;

  const addOverlay = () => {
    if (category === 'Mockups') {
      return (
        <div
          className={`absolute top-0 block h-full w-full overflow-hidden bg-[rgba(33,97,145,0.41)] ${overlayClass}`}
        >
          <div className="h-full w-full animate-slideToCenterFromLeft bg-[rgba(200,230,255,0.5)]" />
        </div>
      );
    }
  };

  const addVideo = () => {
    if (category === 'Mockups' && hasVideoPreview && preview !== null) {
      return (
        <div className={isHovering ? 'absolute z-10 block w-full' : 'hidden'}>
          <video
            muted
            autoPlay
            playsInline
            loop
            className="w-full"
            src={videoPreviewUrl}
            onCanPlayThrough={() => (videoPreviewUrl !== '' ? setLoadedVid(true) : {})}
          />
        </div>
      );
    }
  };

  const onClickEvent = event => {
    openModal(event);
    algoliaInsights.sendStageClickEvent(id, position);
  };
  const isBundle = () => Array.isArray(bundle) && bundle.length > 0;
  const bgs = [
    'bg-gradient-to-t from-indigo-500 via-purple-500 to-pink-500',
    'bg-gradient-to-t from-teal-400 to-yellow-200',
    'bg-gradient-to-t from-emerald-400 to-cyan-400',
    'bg-gradient-to-t from-indigo-400 to-cyan-300',
    'bg-gradient-to-t from-fuchsia-500 to-cyan-500',
    'bg-gradient-to-t from-purple-500 to-purple-800',
    'bg-gradient-to-t from-emerald-500 to-emerald-800',
    'bg-gradient-to-t from-amber-500 to-pink-500',
    'bg-gradient-to-t from-red-500 to-orange-500',
    'bg-gradient-to-t from-amber-200 to-yellow-400',
    'bg-gradient-to-t from-violet-200 to-pink-200',
    'bg-gradient-to-t from-teal-200 to-teal-500',
  ];
  const bg = bgs[Math.floor(Math.random() * bgs.length)];
  const containerBackgroundColor = isBundle() ? 'bg-transparent' : bg;
  const topPosition = isBundle() ? 'top-[47px]' : 'top-4';
  const source =
    category === 'Mockups' && isHovering && !hasVideoPreview && preview !== null
      ? sanitizeUrl(origin + preview)
      : image;

  return (
    <figure
      className={`group relative isolate z-0 flex max-h-full max-w-full flex-col justify-center overflow-hidden ${
        isNewMasonry ? styles.masonryOuterItem : 'w-full'
      }`}
    >
      <a href={link} className="group peer cursor-pointer" onClick={onClickEvent} {...props}>
        <div
          className={`relative block w-full overflow-hidden rounded-lg ${containerBackgroundColor} shadow-sm ${styles.itemInner}`}
          onMouseEnter={hoverIn}
          onMouseLeave={hoverOut}
        >
          {isBundle() ? <BundleElements /> : null}
          {addVideo()}
          <div className="overflow-hidden rounded-t-md">
            <img
              className={`relative z-0 h-full w-full max-w-full object-cover ${
                category !== 'Mockups' ? 'transition duration-500 ease-in-out hover:scale-110' : ''
              }`}
              src={source}
              alt={altText}
              style={{aspectRatio: `1 / ${ratio}`, width: '100%'}}
              onLoad={() => setLoadedImg(true)}
            />
            {addOverlay()}
          </div>
          {free && (
            <Ribbon className="absolute left-4 top-4 z-30" disabled={disabled}>
              FREE
            </Ribbon>
          )}
          <ProductBar className="z-30" type={type} disabled={disabled} />
        </div>
        <Text
          size="lg"
          className={`mt-1 truncate text-ellipsis px-2 ${
            disabled ? 'text-gray-300' : 'text-gray-700'
          }`}
        >
          {title}
        </Text>
      </a>
      <FavoriteIconButton
        className={`right-3.5 ${topPosition} z-30 focus-visible:block focus-visible:outline-none peer-focus-visible:block lg:hidden lg:group-hover:block`}
        favorite={favorite}
        disabled={disabled}
        onClick={onFavoriteClick}
        bundle={isBundle()}
      />
    </figure>
  );
}

TemplateCard.displayName = 'TemplateCard';

TemplateCard.defaultProps = {
  type: 'image',
  free: false,
  disabled: false,
  favorite: false,
  bundle: [],
  preview: null,
  onFavoriteClick: e => e,
  openModal: e => e,
  position: 1,
  width: 256,
  height: 256,
  isNewMasonry: true,
};

TemplateCard.propTypes = {
  /**
   * URL that will redirected by the card
   */
  link: PropTypes.string.isRequired,
  /**
   * Path of the image shown in the card
   */
  image: PropTypes.string.isRequired,
  /**
   * Path of the preview image or video shown in a "Mockup" card while hovering
   */
  preview: PropTypes.string,
  /**
   * Alternative text shown in the card
   */
  altText: PropTypes.string.isRequired,
  /**
   * Bundles the card/stage is part of
   */
  bundle: PropTypes.arrayOf(PropTypes.string),
  /**
   * Template type of the card
   */
  type: PropTypes.oneOf(Object.values(productTypes)),
  /**
   * Title of the card
   */
  title: PropTypes.string.isRequired,
  /**
   * Stage category to define hover effect
   */
  category: PropTypes.string.isRequired,
  /**
   * If this value is true, the Free ribbon is visible
   */
  free: PropTypes.bool,
  /**
   * If this value is true, the card has the disabled styles
   */
  disabled: PropTypes.bool,
  /**
   * If this value is true, the favorite icon shown favorite state
   */
  favorite: PropTypes.bool,
  /**
   * Action when the favorite button is clicked
   */
  onFavoriteClick: PropTypes.func,
  /**
   * Function called to open the PDP modal
   */
  openModal: PropTypes.func,
  /**
   * Stage id, in algolia can be found as ObjectId
   */
  id: PropTypes.number.isRequired,
  /**
   * Position of the item
   */
  position: PropTypes.number,
  /**
   * Path of the large thumb image
   */
  imagePath: PropTypes.string.isRequired,
  /**
   * pixel Width size of thumb image
   */
  width: PropTypes.number,
  /**
   * pixel Height size of thumb image
   */
  height: PropTypes.number,
  /**
   * Is the template card for the new masonry grid?
   */
  isNewMasonry: PropTypes.bool,
};

import PropTypes from 'prop-types';
import GridInfiniteWithAlgolia from './GridInfiniteWithAlgolia';
import GridPaginationWithAlgolia from './GridPaginationWithAlgolia';
import {FavoritesProvider} from '../../../context/FavoritesContext';

/**
 * Grid with Template cards that display Algolia hits. Should only be used inside of InstantSearchProvider component to process Algolia results properly.
 */
export default function GridWithAlgolia({paginationType, ...props}) {
  return (
    <FavoritesProvider>
      {paginationType === 'normal' ? (
        <GridPaginationWithAlgolia {...props} />
      ) : (
        <GridInfiniteWithAlgolia {...props} />
      )}
    </FavoritesProvider>
  );
}

GridWithAlgolia.propTypes = {
  /** The type of pagination to use for the grids */
  paginationType: PropTypes.oneOf(['normal', 'infinite']),
  className: PropTypes.string,
};

GridWithAlgolia.defaultProps = {
  paginationType: 'infinite',
  className: '',
};

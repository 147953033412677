import {useMemo} from 'react';
import PropTypes from 'prop-types';
import Text from '../UI/Atoms/Typography/Text';
import useTopFiltersContext from '../../context/FiltersContext';

export default function TopFilters({filtersContainer, sortBy}) {
  const {filters} = useTopFiltersContext();
  const isEnabled = useMemo(() => {
    return filters.some(element => element.isEnabled);
  }, [filters]);
  return (
    <div className="flex w-full justify-between overflow-auto pb-2 lg:relative">
      <div className={`flex w-full flex-col justify-center ${isEnabled ? 'gap-y-2' : '-mt-2'}`}>
        {filtersContainer}
      </div>
      <div
        className={`absolute right-3.5 top-2.5 flex w-auto shrink-0 items-start lg:right-0 lg:top-0 lg:flex ${
          isEnabled ? 'lg:absolute' : 'ml-4 lg:relative'
        }`}
      >
        <div className="flex w-full items-center gap-x-3">
          <Text className="whitespace-nowrap" size="xl" as="span" weight="semibold">
            Sort by
          </Text>
          {sortBy}
        </div>
      </div>
    </div>
  );
}

TopFilters.propTypes = {
  sortBy: PropTypes.node,
  filtersContainer: PropTypes.node,
};

TopFilters.defaultProps = {
  sortBy: null,
  filtersContainer: null,
};

import PropTypes from 'prop-types';
import {useRef} from 'react';
import {useHits, usePagination} from 'react-instantsearch';
import PaginationWithAlgolia from '../Pagination/PaginationWithAlgolia';
import GridWithTemplateResults from '../Common/Grid/GridWithTemplateResults';
import {defaultGridColumns, defaultGridColumnsPropTypes} from '../../../lib/constants';
import useBucketsCdn from '../../../hooks/useBucketsCdn';

/**
  When the Grid content is loading, for a moment the grid height is 0
  so we are adding a temporal fixed height to avoid the jump from the current
  grid height to 0. Then we set the height to auto and we fix the height again and again.
*/
function loadImages(imagesList) {
  return new Promise(function promise(resolve, reject) {
    let counter = 0;
    imagesList.map(image => {
      const bufferImage = new Image();
      bufferImage.onload = function load() {
        counter++;
        if (counter === 3 || imagesList.length === counter) {
          resolve(true);
        }
      };
      bufferImage.onerror = error => reject(error);
      bufferImage.src = image;
      return image;
    });
  });
}

/**
 * Should only be used inside of InstantSearchProvider component to process Algolia results properly.
 */
export default function GridPaginationWithAlgolia({breakpointColumns, ...props}) {
  const {hits} = useHits(props);
  const {currentRefinement} = usePagination(props);
  const {stageThumbUrl} = useBucketsCdn();

  const {className} = props;

  const scollToRef = useRef();

  const onHandleSelector = () => {
    const grid = scollToRef.current;
    grid.style.height = `${grid.offsetHeight}px`;
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
    const images = hits.map(item => stageThumbUrl(item.large_thumb_path));
    loadImages(images).finally(() => {
      grid.style.height = `auto`;
    });
  };

  return (
    <div className={className}>
      <GridWithTemplateResults
        ref={scollToRef}
        className="mb-12"
        results={hits}
        breakpointColumns={breakpointColumns}
        currentPage={currentRefinement}
      />
      <PaginationWithAlgolia onHandleSelector={onHandleSelector} />
    </div>
  );
}

GridPaginationWithAlgolia.propTypes = {
  className: PropTypes.string,
  /**
   * Number of columns shown in each resolution. See MasonryGrid component for more information.
   */
  breakpointColumns: defaultGridColumnsPropTypes,
};

GridPaginationWithAlgolia.defaultProps = {
  className: '',
  breakpointColumns: defaultGridColumns,
};

GridPaginationWithAlgolia.displayName = 'GridPaginationWithAlgolia';

import PropTypes from 'prop-types';
import {useRef, useEffect, useState} from 'react';
import {useInfiniteHits} from 'react-instantsearch';
import GridWithTemplateResults from '../Common/Grid/GridWithTemplateResults';
import {defaultGridColumns, defaultGridColumnsPropTypes} from '../../../lib/constants';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';

/**
 * Should only be used inside of InstantSearchProvider component to process Algolia results properly.
 */
export default function GridInfiniteWithAlgolia({breakpointColumns, ...props}) {
  const {hits, isLastPage, showMore} = useInfiniteHits(props);
  const {className} = props;
  const bottomSentinel = useRef(null);
  const topSentinel = useRef(null);
  const [isTop, setIsTop] = useState(true);
  const [isBottom, setIsBottom] = useState(false);

  useIntersectionObserver(topSentinel, {}, isIntersecting => {
    if (isIntersecting) setIsTop(true);
    else setIsTop(false);
  });

  useIntersectionObserver(bottomSentinel, {rootMargin: '500px'}, isIntersecting => {
    if (isIntersecting) setIsBottom(true);
    else setIsBottom(false);
  });

  useEffect(() => {
    if (isBottom && !isLastPage && !isTop) showMore();
  }, [isBottom, isTop, isLastPage]);

  return (
    <div className={className}>
      <div ref={topSentinel} />
      <GridWithTemplateResults results={hits} breakpointColumns={breakpointColumns} />
      <div ref={bottomSentinel} />
    </div>
  );
}

GridInfiniteWithAlgolia.propTypes = {
  className: PropTypes.string,
  /**
   * Number of columns shown in each resolution. See MasonryGrid component for more information.
   */
  breakpointColumns: defaultGridColumnsPropTypes,
};

GridInfiniteWithAlgolia.defaultProps = {
  className: '',
  breakpointColumns: defaultGridColumns,
};

GridInfiniteWithAlgolia.displayName = 'GridInfiniteWithAlgolia';

import PropTypes from 'prop-types';
import {useMemo, forwardRef} from 'react';
import Text from '../../../UI/Atoms/Typography/Text';
import {IcCheck01} from '../../../UI/Atoms/Icons';

export const SIZES = {
  md: {
    div: 'h-7',
  },
  lg: {
    div: 'h-9',
  },
};

const ListItem = forwardRef(
  (
    {value, label, count, size, isChecked, handleSelect, className, classNames, ...args},
    forwardedRef
  ) => {
    const {div: divSize} = useMemo(() => SIZES[size] || SIZES.md, [size]);
    const {disabled} = args;
    let checkBgColor = '';
    let disableStyle = '';

    if (isChecked) {
      checkBgColor = 'bg-secondary-500 text-white border-none';
    }

    if (disabled) {
      disableStyle = 'bg-gray-100 text-gray-300';
    }

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <button
        type="button"
        key={value}
        ref={forwardedRef}
        disabled={disabled}
        className={`${divSize} justify-left flex w-full items-center rounded px-2 py-1.5 hover:cursor-pointer hover:bg-gray-100 focus:bg-gray-100 md:h-7 ${disableStyle} ${className} `}
        onClick={() => handleSelect(value)}
        data-testid="list-item"
      >
        <div
          className={`mr-2 flex h-4 min-h-[1rem] w-4 min-w-[1rem] flex-shrink-0 items-center justify-around rounded border ${checkBgColor} ${
            disabled ? `border-gray-300` : `border-gray-400`
          }`}
          data-testid="list-item-checkbox"
        >
          {isChecked && <IcCheck01 className={`h-3 w-3 ${checkBgColor}`} />}
        </div>
        <Text
          size={size}
          className={`truncate pr-1 text-gray-900 ${classNames.label} ${disableStyle}`}
          data-testid="list-item-label"
        >
          {label}
        </Text>
        <Text
          size={size}
          className={`${classNames.counter} ${disabled ? disableStyle : `text-gray-500`} `}
          data-testid="list-item-count"
        >
          {count && `(${count.toLocaleString()})`}
        </Text>
      </button>
    );
  }
);

ListItem.displayName = 'ListItem';

ListItem.defaultProps = {
  isChecked: false,
  count: null,
  size: 'md',
  handleSelect: () => {},
  className: '',
  classNames: {
    label: '',
    counter: '',
  },
};

ListItem.propTypes = {
  /**
   *  The value returned when the item is selected
   */
  value: PropTypes.string.isRequired,
  /**
   *  The text shown next to the checkbox
   */
  label: PropTypes.string.isRequired,
  /**
   *  Sets or removes the checkbox
   */
  isChecked: PropTypes.bool,
  /**
   *  The number shown next to the checkbox's label
   */
  count: PropTypes.number,
  /**
   *  Size of the text and checkbox, can be 'md' or 'lg'
   */
  size: PropTypes.oneOf(Object.keys(SIZES)),
  /**
   *  This function returns the value of the item that was clicked
   */
  handleSelect: PropTypes.func,
  /**
   *  Receives className styles to further customize the styles
   */
  className: PropTypes.string,

  /**
   * Receives classNames styles to further customize the styles
   */
  classNames: PropTypes.shape({
    label: PropTypes.string,
    counter: PropTypes.string,
  }),
};

export default ListItem;

import PropTypes from 'prop-types';

/**
 * - A basic list component. Renders a `<ul>` with each item wrapped in a `<li>`
 */
export default function List({items, classNames}) {
  return (
    <div className={classNames.root}>
      <ul className={`list-none pl-0 ${classNames.list}`}>
        {items.map(item => {
          return (
            <li key={item.key} className={classNames.item}>
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
List.defaultProps = {
  classNames: {
    root: '',
    list: '',
    item: '',
  },
};
List.propTypes = {
  /** Array of items to render in the list. Every item must include a key. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  /** CSS classes to pass to the widget’s elements
   - root: The root element of the component
   - list: The list element
   - item: The li element
   */
  classNames: PropTypes.shape({
    root: PropTypes.string,
    list: PropTypes.string,
    item: PropTypes.string,
  }),
};

import React, {useMemo, forwardRef, useContext} from 'react';
import PropTypes from 'prop-types';
import {PlasmicCanvasContext} from '@plasmicapp/react-web/lib/host';
import useFavorites from '../../../../hooks/useFavorites';
import useFavoritesContext from '../../../../context/FavoritesContext';
import PDPModal from '../../../PDP/Modal';
import NewMasonryGrid from './NewMasonryGrid';
import TemplateCardWithHit from './TemplateCardWithHit';

/**
MasonryGrid using Template cards to display items.
*/
function GridWithTemplateResults({results, currentPage}, ref) {
  const favorites = useFavorites();
  const {getItemsWithFavorites} = useFavoritesContext();
  const items = useMemo(() => getItemsWithFavorites(results), [results, favorites]);
  const inEditor = useContext(PlasmicCanvasContext);

  let openModalCb;
  const openModal = stageId => openModalCb(stageId);
  const registerOpenModal = cb => {
    openModalCb = cb;
  };

  return (
    <div ref={ref}>
      <PDPModal items={items} openModal={registerOpenModal} favorites={favorites} />
      <NewMasonryGrid inEditor={inEditor}>
        {items?.map((data, position) => {
          return (
            <TemplateCardWithHit
              data={data}
              key={data.id}
              favorite={data.is_favorite}
              onFavoriteClick={data.favorite_action}
              openModal={openModal}
              data-id={data.id}
              position={items.length * currentPage + (position + 1)}
            />
          );
        })}
      </NewMasonryGrid>
    </div>
  );
}
const GridWithTemplateResultsWithRef = forwardRef(GridWithTemplateResults);
export default GridWithTemplateResultsWithRef;

GridWithTemplateResults.displayName = 'GridWithTemplateResults';
GridWithTemplateResults.propTypes = {
  /**
   * List of items that it will be shown in the grid.
   * Each item must be have the "id" property
   */
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
  /**
   * Number of the page of the items shown
   */
  currentPage: PropTypes.number,
};

GridWithTemplateResults.defaultProps = {
  results: [],
  currentPage: 0,
};

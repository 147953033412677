import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import {IcChevronLeft, IcChevronRight} from '../../UI/Atoms/Icons';

const PaginationSquare = dynamic(() => import('./PaginationSquare'));

export default function Pagination({
  isFirstPage,
  totalPages,
  isLastPage,
  currentPage,
  pages,
  handleSelection,
}) {
  return (
    <ul className="mx-auto my-1 flex flex-wrap justify-center px-0">
      {/* back arrow of the pagination */}
      {isFirstPage ? null : (
        <PaginationSquare
          className="items-center shadow-none"
          handleClick={() => handleSelection(currentPage - 1)}
          currentPage={currentPage}
          isArrow="left"
        >
          <IcChevronLeft className="h-5 w-5 stroke-cold-gray-800" />
        </PaginationSquare>
      )}
      {/* fix the first two nodes if we're navigating mid list */}
      {currentPage > 3 ? (
        <>
          <PaginationSquare handleClick={() => handleSelection(0)} className="bg-white shadow-md">
            1
          </PaginationSquare>
          <PaginationSquare handleClick={() => handleSelection(1)} className="bg-white shadow-md">
            2
          </PaginationSquare>
          <div className="mx-4 my-1 flex items-center">
            <span>...</span>
          </div>
        </>
      ) : null}
      {/* checks to ommit priting fixed end nodes of list */}
      {pages.map(page =>
        (page === 1 && currentPage > 3) ||
        (page === totalPages - 2 && currentPage < totalPages - 4) ? null : (
          <PaginationSquare
            key={page}
            handleClick={() => handleSelection(page)}
            className={`shadow-md ${page === currentPage ? 'bg-gray-900 text-white' : 'bg-white'}`}
          >
            {page + 1}
          </PaginationSquare>
        )
      )}
      {/* fix the last two nodes if we're navigating mid list */}
      {currentPage < totalPages - 4 ? (
        <>
          <div className="mx-4 my-1 flex items-center">
            <span>...</span>
          </div>
          <PaginationSquare
            handleClick={() => handleSelection(totalPages - 2)}
            className="bg-white shadow-md"
          >
            {totalPages - 1}
          </PaginationSquare>
          <PaginationSquare
            handleClick={() => handleSelection(totalPages - 1)}
            className="bg-white shadow-md"
          >
            {totalPages}
          </PaginationSquare>
        </>
      ) : null}
      {isLastPage ? null : (
        <PaginationSquare
          className="items-center"
          handleClick={() => handleSelection(currentPage + 1)}
          currentPage={currentPage}
          isArrow="right"
        >
          <IcChevronRight className="h-5 w-5 stroke-cold-gray-800" />
        </PaginationSquare>
      )}
    </ul>
  );
}

Pagination.displayName = 'Pagination';

Pagination.defaultProps = {
  isFirstPage: false,
  totalPages: 0,
  isLastPage: false,
  currentPage: 0,
};

Pagination.propTypes = {
  /** is the current page the first page?  */
  isFirstPage: PropTypes.bool,
  /** the total number of pages */
  totalPages: PropTypes.number,
  /** is the current page the last page?  */
  isLastPage: PropTypes.bool,
  /** the current page displayed */
  currentPage: PropTypes.number,
  /** an number array of the pages */
  // eslint-disable-next-line react/forbid-prop-types
  pages: PropTypes.array.isRequired,
  /** function to handle the selected page to display */
  handleSelection: PropTypes.func.isRequired,
};

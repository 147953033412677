import {useMemo, useState, createContext, useContext} from 'react';

export const FiltersContext = createContext({
  filters: [],
  updateFilter: () => null,
});

function getFiltersUpdated(filters, newElement) {
  let newFilters;
  if (filters.some(element => element.name === newElement.name)) {
    newFilters = filters.map(element => {
      if (element.name === newElement.name) {
        return newElement;
      }
      return element;
    });
  } else {
    newFilters = [...filters, newElement];
  }
  return newFilters;
}

export function FiltersProvider({children}) {
  const [filters, setFilters] = useState([]);

  const updateFilter = (name, isEnabled) => {
    setFilters(getFiltersUpdated(filters, {name, isEnabled}));
  };

  const value = useMemo(() => ({filters, updateFilter}), [filters]);

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
}

export default function useTopFiltersContext() {
  const context = useContext(FiltersContext);

  if (context === undefined) {
    throw new Error('useTopFiltersContext must be used within a TopFiltersProvider');
  }

  return context;
}

import {useState} from 'react';
import PropTypes from 'prop-types';
import List from './List';
import Text from '../../Atoms/Typography/Text';
import BaseButton from '../BaseButton';
import {IcChevronExpand, IcChevronCollapse} from '../../Atoms/Icons';

/**
 * - Uses the `List` component to render a list of partial items with a button that allows for fetching of more items. These extra items are shown/hidden through the use of a `BaseButton`.
 * - If you have all the items when initializing the `StatefulList` and want to hide some of the items you should use the `ExpandableList` instead. If you dont' want to hide any of the items you should use `List`.
 */
export default function StatefulList({items, classNames, updateItems, showingMore}) {
  const [isShowingMore, setIsShowingMore] = useState(showingMore);

  const toggleState = () => {
    setIsShowingMore(!isShowingMore);
    updateItems();
  };
  return (
    <div className={classNames.root}>
      <List items={items} classNames={{list: classNames.list, item: classNames.item}} />
      <div className="flex w-full">
        <BaseButton
          className="w-full items-center bg-white px-4 lg:ml-3 lg:w-auto lg:bg-gray-ce-page hover:lg:bg-gray-100"
          variant="textGray"
          rightIcon={
            isShowingMore ? (
              <IcChevronCollapse className="h-5 w-5" />
            ) : (
              <IcChevronExpand className="h-5 w-5" />
            )
          }
          onClick={toggleState}
        >
          <Text className="py-2" size="md" weight="semibold">
            {isShowingMore ? 'Show less filters ' : 'Show more filters '}
          </Text>
        </BaseButton>
      </div>
    </div>
  );
}
StatefulList.defaultProps = {
  showingMore: false,
  classNames: {
    root: '',
    list: '',
    item: '',
  },
};
StatefulList.propTypes = {
  /** Function used to update items. Should toggle between two states. */
  updateItems: PropTypes.func.isRequired,
  /** Array of items to display in the list. Must include all items at init. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  /** Whether the list is showing more, used to determine the text on the button. */
  showingMore: PropTypes.bool,
  /** CSS classes to pass to the widget’s elements
   - root: The root element of the component
   - list: The list element
   - item: The li element
   - showMore: The show more button
   */
  classNames: PropTypes.shape({
    root: PropTypes.string,
    item: PropTypes.string,
    list: PropTypes.string,
  }),
};

import PropTypes from 'prop-types';
import Text from '../Atoms/Typography/Text';
import {IcBundles, IcExpressVideo, IcImage, IcVideo} from '../Atoms/Icons';

const VARIANTS = {
  image: {
    icon: props => {
      return <IcImage {...props} />;
    },
    text: 'Image',
  },
  video: {
    icon: props => {
      return <IcVideo {...props} />;
    },
    text: 'Video',
  },
  expressVideo: {
    icon: props => {
      return <IcExpressVideo {...props} />;
    },
    text: 'Express Video',
  },
  bundle: {
    icon: props => {
      return <IcBundles {...props} />;
    },
    text: 'Bundle',
  },
};

const COLOR_SWITCH = {
  false: 'white',
  true: 'gray-300',
};

/**
Adds a bar with the product type shown.
This component should be inside of other element
*/
export default function ProductBar({type, disabled, className}) {
  const IconTag = VARIANTS[type].icon;

  return (
    <div
      className={`absolute bottom-0 left-0 flex max-h-full w-full items-center bg-gradient-to-t from-gray-600 p-4 ${className}`}
      aria-label="Product Bar"
    >
      <IconTag className={`h-5 w-5 fill-${COLOR_SWITCH[disabled]}`} />
      <Text className={`text-${COLOR_SWITCH[disabled]} ml-2`} size="lg">
        {VARIANTS[type].text}
      </Text>
    </div>
  );
}

ProductBar.defaultProps = {
  type: 'image',
  disabled: false,
  className: '',
};

ProductBar.propTypes = {
  /**
   * Product Bar type will be shown
   */
  type: PropTypes.oneOf(['image', 'video', 'expressVideo', 'bundle']),
  /**
   * Defines if the Product Bar will shown disabled styles
   */
  disabled: PropTypes.bool,
  /**
   * The optional CSS classes to be applied on the preview
   */
  className: PropTypes.string,
};

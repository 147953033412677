import PropTypes from 'prop-types';
import {useRefinementList} from 'react-instantsearch';
import RefinementList from '../UI/Molecules/Lists/RefinementList';
import removeInvisibleTags from '../../lib/algolia/invisible-tags';

/**
- Built using the react-instantsearch library and the  [RefinementList](https://www.algolia.com/doc/api-reference/widgets/refinement-list/react-hooks/#hook-example) API
- Displays list of facets using the ExpandableList component
* */
export default function RefinementListWithAlgolia({...props}) {
  const {items, refine, toggleShowMore, isShowingMore, canToggleShowMore} =
    useRefinementList(props);
  const {title, showMore} = props;
  return (
    <RefinementList
      items={items}
      title={title}
      refine={refine}
      canToggleShowMore={canToggleShowMore}
      isShowingMore={isShowingMore}
      showMore={showMore}
      loadMore={toggleShowMore}
    />
  );
}

function transformItemsFunc(items, metadata) {
  return removeInvisibleTags(items, metadata).map(item => ({
    ...item,
    key: item.label.toLowerCase().split(' ').join(),
  }));
}

RefinementListWithAlgolia.defaultProps = {
  title: '',
  attribute: '',
  sortBy: ['name'],
  operator: 'or',
  showMore: false,
  showMoreLimit: 20,
  limit: 100,
  transformItems: transformItemsFunc,
};

RefinementListWithAlgolia.propTypes = {
  /* Receives the items and is called before displaying them. Should return a new array with the same shape as the original array. Useful for transforming, removing, or reordering items. */
  transformItems: PropTypes.func,
  /** Label used at the top of the list. */
  title: PropTypes.string,
  /** The name of the attribute in the records. */
  attribute: PropTypes.string,
  /** How many facet values to retrieve. This is the max amount of items displayed in the ExpandableList */
  limit: PropTypes.number,
  /** How to sort refinements */
  sortBy: PropTypes.oneOfType([
    PropTypes.oneOf([
      'count',
      'count:asc',
      'count:desc',
      'name',
      'name:asc',
      'name:desc',
      'isRefined',
      'isRefined:asc',
      'isRefined:desc',
    ]),
    PropTypes.array,
  ]),
  /** How the facets are combined. */
  operator: PropTypes.oneOf(['or', 'and']),
  /** Whether to display a button that expands the number of items. */
  showMore: PropTypes.bool,
  /** The maximum number of items to display if the widget is showing more items. */
  showMoreLimit: PropTypes.number,
};

export const PlasmicProps = {
  name: 'RefinementList',
  props: {
    attribute: {
      type: 'string',
      defaultValue: 'stage_tags',
    },
    limit: {
      type: 'number',
      defaultValue: 10,
    },
    sortBy: {
      type: 'choice',
      multiSelect: true,
      options: [
        'count',
        'count:asc',
        'count:desc',
        'name',
        'name:asc',
        'name:desc',
        'isRefined',
        'isRefined:asc',
        'isRefined:desc',
      ],
      defaultValue: ['name'],
    },
    operator: {
      type: 'choice',
      options: ['or', 'and'],
      defaultValue: 'or',
    },
    showMore: {
      type: 'boolean',
      defaultValue: false,
    },
    showMoreLimit: {
      type: 'number',
      defaultValue: 20,
    },
    title: {
      type: 'string',
      defaultValue: '',
    },
  },
  importPath: './components/Discovery/RefinementListWithAlgolia',
  isDefaultExport: true,
};

/**
 * Generate new array by filtering out items that appear on the invisible tags
 * facet.
 *
 * @param {Array} items - The items to be filtered
 * @param {object} metadata - The Algolia's response parameters
 *
 * @returns {Array} - New array containing the visible facet values
 */
export default function removeInvisibleTags(items, metadata) {
  const invisibleTags = metadata.results?.facets.find(
    facet => facet.name === 'invisible_tags'
  )?.data;

  if (invisibleTags === undefined) {
    return items;
  }

  const invisibleTagsValues = Object.keys(invisibleTags);

  return items.filter(refinement => !invisibleTagsValues.includes(refinement.value));
}

import PropTypes from 'prop-types';
import {useCallback} from 'react';
import ListItem from '../../../Discovery/Common/ListItem/ListItem';
import Text from '../../Atoms/Typography/Text';
import List from './List';
import StatefulList from './StatefulList';

/**
- Pure UI component for filter lists used in CEs and search page
* */
export default function RefinementList({
  size,
  title,
  items,
  refine,
  isShowingMore,
  canToggleShowMore,
  showMore,
  loadMore,
}) {
  const cachedRefine = useCallback(
    refinement => {
      refine(refinement);
    },
    [refine]
  );

  const listItems = items.map(item => {
    return (
      <ListItem
        key={item.value}
        value={item.value}
        label={item.label}
        isChecked={item.isRefined}
        count={item.count}
        handleSelect={cachedRefine}
      />
    );
  });

  return (
    <div className="mb-2 w-full">
      <div className="relative py-1 text-gray-400 lg:px-2 lg:before:absolute lg:before:inset-x-0 lg:before:left-0 lg:before:top-1/2 lg:before:h-px lg:before:w-full lg:before:bg-gray-200 lg:before:content-['']">
        <Text
          size={size}
          className="relative mb-1 w-min bg-white lg:mb-0 lg:bg-gray-ce-page lg:px-1"
        >
          {title}
        </Text>
      </div>
      {showMore && canToggleShowMore ? (
        <StatefulList items={listItems} updateItems={loadMore} showingMore={isShowingMore} />
      ) : (
        <List items={listItems} />
      )}
    </div>
  );
}

RefinementList.defaultProps = {
  size: 'md',
  canToggleShowMore: false,
  isShowingMore: false,
  showMore: false,
};
RefinementList.propTypes = {
  /** size of the text items */
  size: PropTypes.oneOf(['md', 'lg']),
  /** The title of the filter  */
  title: PropTypes.string.isRequired,
  /** Refine function */
  refine: PropTypes.func.isRequired,
  /** canToggleShowMore */
  canToggleShowMore: PropTypes.bool,
  /** Whether a show more button option should be given */
  showMore: PropTypes.bool,
  /** Whether the list is showing more, used to determine the text on the button. */
  isShowingMore: PropTypes.bool,
  /** Function used to fetch more items */
  loadMore: PropTypes.func.isRequired,
  /** Array of items to display in the list. Must include all items at init. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      count: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

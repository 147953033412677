import PropTypes from 'prop-types';
import {forwardRef} from 'react';
import Text from '../Atoms/Typography/Text';
import {IcCloseSm} from '../Atoms/Icons';

const FilterChip = forwardRef(({label, value, size, removeRefinement, type, attribute}, ref) => {
  return (
    <button
      ref={ref}
      type="button"
      className="group flex cursor-pointer items-center space-x-2 rounded-md bg-cold-gray-300 px-3 py-1.5 text-cold-gray-800 hover:bg-cold-gray-400 disabled:bg-gray-100 disabled:text-gray-300"
      onClick={() => removeRefinement({type, attribute, value})}
    >
      <Text size={size}>{label}</Text>{' '}
      <IcCloseSm className="h-4 w-4 group-disabled:fill-gray-300" />
    </button>
  );
});
FilterChip.displayName = 'FilterChip';
FilterChip.defaultProps = {
  size: 'md',
};
FilterChip.propTypes = {
  /** The raw value of the refinement. */
  value: PropTypes.string.isRequired,
  /** The type of the refinement. */
  type: PropTypes.string.isRequired,
  /** The attribute on which the refinement is applied. */
  attribute: PropTypes.string.isRequired,
  /** Text used inside the chip. */
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['md', 'lg']),
  /** Removal function from provider. */
  removeRefinement: PropTypes.func.isRequired,
};

export default FilterChip;

/* eslint no-labels: ["error", { "allowLoop": true }] */
/* eslint-disable no-continue */
import PropTypes from 'prop-types';
import {useEffect, useRef, useState, Children} from 'react';
import {breakpoints} from '../../../../lib/constants';

import styles from './NewMasonryGrid.module.css';

const breaks = [
  {count: 3, className: styles.fourBreak},
  {count: 5, className: styles.fiveBreak},
  {count: 6, className: styles.sixBreak},
  {count: 7, className: styles.sevenBreak},
  {count: 8, className: styles.eightBreak},
];

function NewMasonryGrid({children, inEditor}) {
  let tallestColHeight = 9000;
  let numColumns = 2;
  let averageItemHeight = 200;
  let aproxColWidth = 200;

  const [items, setItems] = useState(Children.toArray(children));
  const [itemHash, setItemHash] = useState('');
  const [containerHeight, setContainerHeight] = useState(`${tallestColHeight}px`);
  const containerRef = useRef(null);

  function cHasher() {
    return Children.toArray(children).reduce((p, c) => String(p) + c.key.replace(/\.\$/, ''), 0);
  }

  function getItemRefs(key) {
    if (key) {
      const id = key.replace(/\.\$(\d+)/, '$1');
      return document.querySelector(`[data-id="${id}"]`);
    }
    return Array.from(containerRef.current.children).filter(child => child.tagName === 'FIGURE');
  }

  function averageDistanceFromMean(numbers) {
    const mean = numbers.reduce((acc, num) => acc + num, 0) / numbers.length;
    const totalDistance = numbers.reduce((acc, num) => acc + Math.abs(num - mean), 0);
    return totalDistance / numbers.length;
  }

  /* Make column heights more similar.
    Compare element heights at tallest/shortest columns
    of each row starting at the bottom.  Swap positions
    of the two elements if the swap will help balance.
  */
  function balance() {
    const myItems = Children.toArray(children);
    const rowCount = Math.floor(myItems.length / numColumns) - 1;

    for (let rowIndex = rowCount; rowIndex >= 4; rowIndex--) {
      recalcAfterMove: for (let rowAttempt = 0; rowAttempt < numColumns / 2; rowAttempt++) {
        let columnHeights = getHeightsFromData(myItems);
        const sortedColumnHeights = [...columnHeights].sort((a, b) => a - b);
        for (let shortIndex = 0; shortIndex <= numColumns; shortIndex++) {
          for (let tallIndex = numColumns - 1; tallIndex >= 0; tallIndex--) {
            if (sortedColumnHeights[shortIndex] > sortedColumnHeights[tallIndex]) {
              continue;
            }

            const tallestColumnIndex = columnHeights.indexOf(sortedColumnHeights[tallIndex]);
            const shortestColumnIndex = columnHeights.indexOf(sortedColumnHeights[shortIndex]);
            const tallColItemIndex = rowIndex * numColumns + tallestColumnIndex;
            const shortColItemIndex = rowIndex * numColumns + shortestColumnIndex;
            const tallColElement = myItems[tallColItemIndex];
            const shortColElement = myItems[shortColItemIndex];

            if (
              tallColElement?.props?.data?.product_thumb_height <
              shortColElement?.props?.data?.product_thumb_height
            ) {
              continue;
            }

            const scoreBefore = averageDistanceFromMean(columnHeights);
            myItems[shortColItemIndex] = tallColElement;
            myItems[tallColItemIndex] = shortColElement;

            // check - TODO, figure out why this is needed
            columnHeights = getHeightsFromData(myItems);
            const scoreAfter = averageDistanceFromMean(columnHeights);
            if (scoreBefore < scoreAfter) {
              myItems[shortColItemIndex] = shortColElement;
              myItems[tallColItemIndex] = tallColElement;
              continue;
            }
            continue recalcAfterMove;
          }
        }
      }
    }
    setItems(myItems);
  }

  const getHeightsFromData = myItems => {
    const dataHeights = new Array(numColumns).fill(0);
    myItems.forEach((item, index) => {
      const colNum = index % numColumns;
      if (!item?.props?.data?.large_thumb_height) {
        console.error('no height data on colNum', colNum);
        dataHeights[colNum] += aproxColWidth;
      } else {
        dataHeights[colNum] +=
          ((item?.props?.data?.large_thumb_height || aproxColWidth) * aproxColWidth) /
          (item?.props?.data?.large_thumb_width || aproxColWidth);
      }
    });
    return dataHeights;
  };

  function getColumnHeights() {
    averageItemHeight = 0;
    const heights = new Array(numColumns).fill(0);
    const itemRefs = getItemRefs();
    itemRefs.forEach((figure, index) => {
      const order = parseInt(window.getComputedStyle(figure).order, 10) || index;
      heights[order % numColumns] += figure.offsetHeight;
      averageItemHeight += figure.offsetHeight;
    });
    averageItemHeight = Math.ceil(averageItemHeight / itemRefs.length);
    return heights;
  }

  function fixContainerHeight() {
    tallestColHeight = Math.max(...getColumnHeights()) + 10;
    const height = tallestColHeight < 400 ? '400px' : `${tallestColHeight}px`;
    setContainerHeight(height);
  }

  const updateDimensions = () => {
    const deviceWidth = window.innerWidth;

    if (deviceWidth >= breakpoints['4xl']) {
      numColumns = 8;
      aproxColWidth = 256;
    } else if (deviceWidth >= breakpoints['3xl']) {
      numColumns = 7;
      aproxColWidth = 271;
    } else if (deviceWidth >= breakpoints['2xl']) {
      numColumns = 6;
      aproxColWidth = 256;
    } else if (deviceWidth >= breakpoints.xl) {
      numColumns = 5;
      aproxColWidth = 256;
    } else if (deviceWidth >= breakpoints.lg) {
      numColumns = 4;
      aproxColWidth = 198;
    } else if (deviceWidth >= breakpoints.md) {
      numColumns = 4;
      aproxColWidth = 225;
    } else if (deviceWidth >= breakpoints.sm) {
      numColumns = 3;
      aproxColWidth = 200;
    } else {
      numColumns = 2;
      aproxColWidth = 180;
    }

    tallestColHeight = Math.ceil((averageItemHeight * items.length) / numColumns) * 2;
    setContainerHeight(`${tallestColHeight}px`);

    balance();
    fixContainerHeight();
    setTimeout(() => {
      fixContainerHeight();
    }, 500);
  };

  useEffect(() => {
    let resizeTimeout;

    const debounce = () => {
      setContainerHeight(`${tallestColHeight * 2}px`);
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(updateDimensions, 200);
    };

    const updateTimeout = setTimeout(updateDimensions, 200);

    if (children && containerRef.current) {
      const currentHash = cHasher();
      if (currentHash !== itemHash) {
        setItemHash(currentHash);
      }
    }

    window.addEventListener('resize', debounce);

    return () => {
      clearTimeout(updateTimeout);
      clearTimeout(resizeTimeout);
      window.removeEventListener('resize', debounce);
    };
  }, [children, itemHash]);

  let breakcount = 0;
  return (
    <div
      className={styles.masonryContainer}
      ref={containerRef}
      style={{height: !inEditor ? containerHeight : '3000px'}}
    >
      {items}
      {breaks.map(breakItem =>
        Array.from({length: breakItem.count}).map(number => (
          <span
            key={`${number}-${breakcount++}`}
            className={`${styles.masonryOuterItem} ${breakItem.className}`}
          />
        ))
      )}
    </div>
  );
}

export default NewMasonryGrid;

NewMasonryGrid.displayName = 'NewMasonryGrid';
NewMasonryGrid.propTypes = {
  /**
   * List of items that it will be shown in the grid.
   * Each item must be have the "id" property
   */
  children: PropTypes.node,
  /**
   * Flag tod determine if component is rendered on Plasmic
   * Studio, or on Client browser
   */
  inEditor: PropTypes.bool,
};

NewMasonryGrid.defaultProps = {
  children: null,
  inEditor: false,
};

import PropTypes from 'prop-types';
import {IcFavoriteOn} from '../../Atoms/Icons';
import IconButton from './index';

const VARIANTS_SWITCH = {
  true: 'fill-danger-500 hover:fill-white',
  false: 'fill-white hover:fill-danger-500',
};

const ICON_SIZES = {
  '2xl': ' h-10 w-10',
  xl: ' h-8 w-8',
  lg: ' h-6 w-6',
  md: ' h-5 w-5',
  sm: ' h-4 w-4',
};

/**
- Should only be used to add a heart icon with a color depending on the state
- The heart is placed on the top-right corner.
- The parent element must be relative
* */
export default function FavoriteIconButton({favorite, onClick, disabled, size, className}) {
  return (
    <IconButton
      className={`group absolute ${className}`}
      onClick={onClick}
      disabled={disabled}
      aria-label="Favorite"
      icon={
        <IcFavoriteOn
          className={`transition-transform group-enabled:drop-shadow-[0_0_2px_rgba(0,0,0,0.3)] group-hover:group-enabled:scale-125 group-focus:group-enabled:scale-125 group-focus-visible:group-enabled:scale-150 group-active:group-enabled:scale-150 group-disabled:fill-gray-300 group-disabled:drop-shadow-none ${VARIANTS_SWITCH[favorite]} ${ICON_SIZES[size]}`}
        />
      }
    />
  );
}

FavoriteIconButton.defaultProps = {
  size: 'lg',
  disabled: false,
  onClick: e => e,
  favorite: false,
  className: '',
};

FavoriteIconButton.propTypes = {
  /**
   * This value defines the Icon size. The sizes are the same as Icon component.
   */
  size: PropTypes.oneOf(['2xl', 'xl', 'lg', 'md', 'sm']),
  /**
   * This value defines the favorite status shown
   */
  favorite: PropTypes.bool,
  /**
   * This value defines if the favorite is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Action performed after element is clicked
   */
  onClick: PropTypes.func,
  /**
   * The optional CSS class to be applied on the Icon button
   */
  className: PropTypes.string,
};

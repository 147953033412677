import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import {useContext, forwardRef} from 'react';
import {ItemIndicator, Item, ItemText} from '@radix-ui/react-select';
import {IcCheck01} from '../Atoms/Icons';
import {SelectContext, VARIANT} from './Select';

const Text = dynamic(() => import('../Atoms/Typography/Text'));

const Option = forwardRef(function Option({label, className, value, ...props}, ref) {
  const {size, variant, theme} = useContext(SelectContext);
  const currentVariant = VARIANT[variant];
  const variantTheme = currentVariant.theme[theme];
  const variantSizes = currentVariant.sizes[size];
  return (
    <Item
      ref={ref}
      value={value}
      {...props}
      className={`${currentVariant.option} ${variantSizes.option} ${variantTheme.option} ${className} outline-none`}
    >
      <Text size={variantSizes.text} className={currentVariant.optionText}>
        <ItemText>{label}</ItemText>
      </Text>
      <ItemIndicator asChild>
        <span className="m-auto mr-2.5">
          <IcCheck01 className={`${variantSizes.checkIcon} px-0.5`} />
        </span>
      </ItemIndicator>
    </Item>
  );
});
Option.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
};
Option.defaultProps = {
  label: null,
  className: '',
};

export default Option;

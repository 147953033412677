import PropTypes from 'prop-types';
import TemplateCard from '../../../UI/Card/TemplateCard';
import {BUNDLE_TYPE_ID, productTypes} from '../../../../lib/constants';
import useBucketsCdn from '../../../../hooks/useBucketsCdn';

/**
- This component is a mapper between TemplateCard properties and Algolia hit properties
 */
export default function TemplateCardWithHit({data, ...props}) {
  const type = data.bundle_tags?.length > 0 ? BUNDLE_TYPE_ID : data.image_type_id;
  const {stageThumbUrl} = useBucketsCdn();

  return (
    <TemplateCard
      width={data.product_thumb_width || data.large_thumb_width}
      height={data.product_thumb_height || data.large_thumb_height}
      link={data.stage_link}
      image={stageThumbUrl(data.large_thumb_path)}
      preview={data.preview_image_path}
      altText={data.seo_title}
      title={data.seo_title}
      category={data.category_name}
      free={data.is_free}
      type={productTypes[type]}
      bundle={data.bundle_tags ? data.bundle_tags : []}
      id={data.id}
      imagePath={data.large_thumb_path}
      {...props}
    />
  );
}

TemplateCardWithHit.displayName = 'TemplateCardWithHit';

TemplateCardWithHit.propTypes = {
  /**
   * Data with the Algolia hit properties
   */
  data: PropTypes.shape({
    /**
     * Stage id, in algolia can be found as ObjectId
     */
    id: PropTypes.number.isRequired,
    /**
     * URL that will redirected by the card
     */
    stage_link: PropTypes.string.isRequired,
    /**
     * Path of the image shown in the card
     */
    large_thumb: PropTypes.string.isRequired,
    /**
     * Path of the preview shown in the card
     */
    preview_image_path: PropTypes.string,
    /**
     * Title and alternative text shown in the card
     */
    seo_title: PropTypes.string.isRequired,
    /**
     * Stage category to define hover effect
     */
    category_name: PropTypes.oneOf(['Design Templates', 'Logos', 'Mockups', 'Videos']).isRequired,
    /**
     * Template type of the card. Each number is related to a template type
      - 13: 'image'
      - 15: 'video'
      - 16: 'bundle'
      - 18: 'expressVideo'
     */
    image_type_id: PropTypes.oneOf([13, 15, 16, 18]),
    /**
     * If this value is true, the Free ribbon is visible
     */
    is_free: PropTypes.bool,
    /**
     * Array containing the bundle tags the card/stage is part of
     */
    bundle_tags: PropTypes.arrayOf(PropTypes.string),
    /**
     * Path of the large thumb (Renamed to image)
     */
    large_thumb_path: PropTypes.string.isRequired,
    /**
     * Width of the thumb (Renamed to image)
     */
    product_thumb_width: PropTypes.number,
    /**
     * Width of the large thumb (Renamed to image)
     */
    large_thumb_width: PropTypes.number,
    /**
     * Height of the thumb (Renamed to image)
     */
    product_thumb_height: PropTypes.number,
    /**
     * Height of the large thumb (Renamed to image)
     */
    large_thumb_height: PropTypes.number,
  }).isRequired,
};

import {useEffect} from 'react';

/**
 * Custom hook to freeze the document body. Used in modal and panel components.
 *
 * @param {boolean} toogle - isOpen or isActive from a useState.
 *
 * @returns void
 */
export default function useEffectFreezeBody(toogle) {
  useEffect(() => {
    if (toogle) {
      document.body.classList.add('overflow-hidden', 'fixed', 'w-screen');
    } else {
      document.body.classList.remove('overflow-hidden', 'fixed', 'w-screen');
    }
  }, [toogle]);
}
